/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouteLoaderData } from 'react-router'
import { type loader } from '~/root'

function isUser(user: any) {
	return user && typeof user === 'object' && typeof user.id === 'string'
}

export function useOptionalUser() {
	const data = useRouteLoaderData<typeof loader>('root')
	if (!data || !isUser(data.user)) {
		return undefined
	}
	return data.user
}

export function useUser() {
	const maybeUser = useOptionalUser()
	if (!maybeUser) {
		throw new Error(
			'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
		)
	}
	return maybeUser
}

export function useIsAdmin() {
	const maybeUser = useOptionalUser()
	if (maybeUser?.roles.some(x => x.name === 'admin')) {
		return true
	}
	return false
}
